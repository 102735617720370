export default {
    'nav.dashboard': 'Dashboard',
    'nav.product': 'Product',
    'nav.productList': 'In House Products',
    'nav.productBrand': 'Brand',
    'nav.productCategory': 'Category',
    'nav.productSubCategory': 'Sub Category',
    'nav.productSubSubCategory': 'Sub Subcategory',
    'nav.productAttribute': 'Attribute',
    'nav.productMarketing': 'Marketing',
    'nav.productMarketingFlash': 'Flash Deals',
    'nav.productShipping': 'Shipping',
    'nav.productShippingConfiguration': 'Shipping Configuration',
    'nav.productRegion': 'Region',
    'nav.productCity': 'City',
    'nav.productArea': 'Area',
    'nav.productSetup': 'Setup & Configurations',
    'nav.productSetupGeneral': 'General Settings',
    'nav.productSetupPages': 'Pages',
    'nav.productSetupProduct': 'Product Settings',
}
